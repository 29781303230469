// @format

import { Litepicker } from "litepicker";
import "litepicker/dist/plugins/ranges";
import Chartkick from "chartkick";

function dateRange(start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }

  return arr;
}

function filteredDates(chartData, dates) {
  const _chartData = JSON.parse(JSON.stringify(chartData));

  for (var dataset of _chartData) {
    for (const [k, v] of Object.entries(dataset.data)) {
      if (!dates.includes(k)) {
        delete dataset.data[k];
      }
    }
  }

  return _chartData;
}

export function initLitepicker(): void {
  const pickerElement = document.getElementById("litepicker");

  if (pickerElement && !pickerElement.dataset.loaded) {
    pickerElement.dataset.loaded = "true";
    pickerElement.classList.remove("disabled");
    const today = new Date();
    const allTime = [
      new Date(1970, 0, 1),
      new Date(today.setHours(0, 0, 0, 0)),
    ];
    const last7Days = [
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6),
      new Date(today.setHours(0, 0, 0, 0)),
    ];
    const last14Days = [
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13),
      new Date(today.setHours(0, 0, 0, 0)),
    ];
    const last30Days = [
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29),
      new Date(today.setHours(0, 0, 0, 0)),
    ];
    const picker = new Litepicker({
      element: pickerElement,
      plugins: ["ranges"],
      ranges: {
        customRanges: {
          "All time": allTime,
          "Last 7 days": last7Days,
          "Last 14 days": last14Days,
          "Last 30 days": last30Days,
        },
      },
    });

    picker.on("selected", function (date1, date2) {
      const timestamp1 = date1.dateInstance.getTime();
      const timestamp2 = date2.dateInstance.getTime();
      let args = { start_date: timestamp1, end_date: timestamp2 };

      if (timestamp1 === +last7Days[0] && timestamp2 === +last7Days[1]) {
        $("#litepicker").html("<i class='fas fa-calendar'></i> Last 7 days");
      } else if (
        timestamp1 === +last14Days[0] &&
        timestamp2 === +last14Days[1]
      ) {
        $("#litepicker").html("<i class='fas fa-calendar'></i> Last 14 days");
      } else if (
        timestamp1 === +last30Days[0] &&
        timestamp2 === +last30Days[1]
      ) {
        $("#litepicker").html("<i class='fas fa-calendar'></i> Last 30 days");
      } else if (timestamp1 === +allTime[0] && timestamp2 === +allTime[1]) {
        args.start_date = 0;
        $("#litepicker").html("<i class='fas fa-calendar'></i> All time");
        picker.clearSelection();
      } else {
        $("#litepicker").html(
          "<i class='fas fa-calendar'></i> " +
            date1.dateInstance.toDateString().substring(4) +
            " - " +
            date2.dateInstance.toDateString().substring(4),
        );
      }

      const statsURL = $("#render-async-stats").data("url");
      $.get(statsURL, args, function (data) {
        $("#render-async-stats").html(data);
        document.dispatchEvent(new Event("render_async_load"));
      });
    });
  }
}
